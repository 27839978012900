import Swiper, { Autoplay, Navigation } from 'swiper';

export const home = () => {
    Swiper.use([Navigation, Autoplay]);

    const homeHeroSwiper = new Swiper(".hero-swiper", {
        mousewheel: {
            enabled: false,
            invert: true,
        },
        // Optional parameters
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,
        loopedSlides: 1,
        speed: 300,
        // Enable lazy loading
        lazy: false,
        navigation: {
            nextEl: '.slider-arrow.right',
            prevEl: '.slider-arrow.left',
        },
        keyboard: {
            enabled: true,
        },
        breakpoints: {
            0: {
                /* when window >=0px - webflow mobile portriat */
                slidesPerView: 1,
            },
            478: {
                /* when window >= 478px - webflow mobile landscape */
                slidesPerView: 1,
            },
            767: {
            /* when window >= 767px - webflow tablet */ slidesPerView: 1,
            },
            988: {
            /* when window >= 988px - webflow desktop */ slidesPerView: 1,
            },
            1920: {
            /* when window >= 988px - webflow desktop */ slidesPerView: 1,
            },
        },
        // uncomment if you want autoplay slider
        autoplay: {
        delay: 5000,
        },
        /* uncomment if you want scrollbar
            scrollbar: {
                el: '.swiper-scrollbar',
                hide: true,
            },
        */
    });

    //console.log(homeHeroSwiper);
}